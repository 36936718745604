import { Component, OnInit } from '@angular/core';
import { DEFAULT_LOGO_PATH, DEFAULT_TITLE } from './header.consts';
import {environment} from '../../../../environments/environment';
import {EnvironmentType} from '../../../core/services/auth-service/types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor() {}

  headerIconURL: string = DEFAULT_LOGO_PATH;
  headerTitle: string = DEFAULT_TITLE;

  environment = environment;

  get isHeaderBlueWithoutLogo(): boolean {
    return [EnvironmentType.UAT, EnvironmentType.SANDBOX, EnvironmentType.STAGE, EnvironmentType.KHR_AWS_PROD].includes(
      environment.type,
    );
  }

  ngOnInit(): void {}
}
