<div class="header header-fill-size">
  <div class="header-content">
    <div class="header-content-logos">
      <!-- <div class="main-logo">
        <img src="{{ headerIconURL }}" alt="Main logo" class="main-logo-img" />
        <h2 class="main-logo-text">
          {{ headerTitle }}
        </h2>
      </div> -->
      <img src="assets/logos/city3.svg" alt="City logo" class="city-logo" />
    </div>
  </div>
</div>
